const commonMethods = {
  computed: {
    getOprtStatus() {
      switch (this.detailInfo.orderInfo.orderStatus) {
        case 'DFH':
          return 'FH';
        case 'DSH':
          return 'SH';
        case 'DKP':
          return 'KP';
        case 'DZF':
          return 'ZF';
        case 'ZFC':
          return 'SK';
        case 'QXC':
          return 'QXS';
      }
    }
  }
};

const formatDialogFile = {
  data() {
    return {
      jpg: require('@/assets/jpg.png'),
      pdf: require('@/assets/pdf.png'),
      zip: require('@/assets/zip.png')
    };
  },
  mounted() {
    if (this.info.orderInfo.tprocAudit.approveStatus == '1') this.getfillingData();
  },
  watch: {
    // 监听审核状态，用来调用回显接口以及清空上一次输入的数据
    'info.orderInfo.tprocAudit.approveStatus': async function(val) {
      if (val == '1') {
        // 调用重置数据接口
        this.resetData();
        // 获取回显数据
        this.getfillingData();
      }
      if (val == '3' || val == '0') this.resetData();
    }
  },
  methods: {
    // 格式化文件数据(因为后台传来的数据是字符串，完全不合理。所以要多写一个方法去格式化数据)
    formatFile(data) {
      let myArr = [],
        name = [],
        url = [];
      if (data.fileUrl.substring(0, 1) === '[') {
        url = JSON.parse(data.fileUrl);
        name = JSON.parse(data.fileName);
      } else {
        url = data.fileUrl.split(',').filter(Boolean);
        name = data.fileName.split(',').filter(Boolean);
      }
      // 对获取的数据进行格式化
      url.forEach((item, index) => {
        let myObj = {};
        if (item.slice(-3) == 'zip') {
          myObj.formatPicture = this.zip;
        } else if (item.slice(-3) == 'pdf') {
          myObj.formatPicture = this.pdf;
        } else {
          myObj.formatPicture = this.jpg;
        }
        myObj.name = name[index];
        myObj.url = item;
        myArr.push(myObj);
      });
      return this.myPagination(myArr);
    },
    myPagination(data) {
      let myArr = [];
      let paginationArr = [];
      let myObj = {};
      let num = data.length / 8;
      for (let i = 0; i < num; i++) {
        myArr = data.slice(i * 8, i * 8 + 8);
        paginationArr.push(myArr);
      }
      myObj.arr = paginationArr;
      myObj.total = data.length;
      return myObj;
    }
  }
};
export {formatDialogFile, commonMethods};
