var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "review" },
    [
      _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "3"
        ? _c("div", { staticClass: "review-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/order/nopass.png"), alt: "" },
            }),
            _vm._m(0),
          ])
        : _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "approveStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "review-radio",
                      model: {
                        value: _vm.ruleForm.approveStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "approveStatus", $$v)
                        },
                        expression: "ruleForm.approveStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("审核通过"),
                      ]),
                      _c("el-radio", { attrs: { label: "3" } }, [
                        _vm._v("审核不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "approveRemarks" } },
                [
                  _c("el-input", {
                    staticClass: "review-textarea",
                    attrs: {
                      type: "textarea",
                      maxlength: "170",
                      "show-word-limit": "",
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.ruleForm.approveRemarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "approveRemarks", $$v)
                      },
                      expression: "ruleForm.approveRemarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "1"
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "padding-top": "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "165px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("提交成功，审核不同通过")]),
      _c("p", [_vm._v("待重新填写信息和上传资料之后再次进行审核")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }