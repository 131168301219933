<template>
  <div class="review">
    <div class="review-content" v-if="detailInfo.orderInfo.tprocAudit.approveStatus == '3'">
      <img src="@/assets/order/nopass.png" alt="" />
      <div class="text">
        <p>提交成功，审核不同通过</p>
        <p>待重新填写信息和上传资料之后再次进行审核</p>
      </div>
    </div>
    <el-form v-else :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item prop="approveStatus">
        <el-radio-group class="review-radio" v-model="ruleForm.approveStatus">
          <el-radio label="2">审核通过</el-radio>
          <el-radio label="3">审核不通过</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="approveRemarks">
        <el-input class="review-textarea" type="textarea" maxlength="170" show-word-limit placeholder="请输入内容" v-model="ruleForm.approveRemarks" />
      </el-form-item>
    </el-form>

    <div v-if="detailInfo.orderInfo.tprocAudit.approveStatus == '1'" style="display: flex;justify-content: center;padding-top:50px">
      <el-button @click="submit" style="width:165px" type="primary">提交</el-button>
    </div>
  </div>
</template>
<script>
import {commonMethods} from '@/modules/mixin/order-mixin.js';
import {getPassAudit, getRejectAudit} from '../../api.js';
export default {
  name: 'Review',
  data() {
    return {
      ruleForm: {
        approveStatus: '2',
        approveRemarks: '同意'
      },
      rules: {
        approveStatus: [{required: true, message: '请选择处理状态', trigger: 'change'}],
        approveRemarks: [{required: true, message: '请输入处理原因', trigger: 'blur'}]
      }
    };
  },
  mixins: [commonMethods],
  props: {
    detailInfo: {
      type: Object
    }
  },
  watch: {
    'ruleForm.approveStatus': function(val) {
      this.ruleForm.approveRemarks = '';
      if (val == '2') this.ruleForm.approveRemarks = '同意';
    }
  },
  methods: {
    submit() {
      let params = {
        approveStatus: this.ruleForm.approveStatus,
        orderId: this.$route.query.orderid,
        status: this.getOprtStatus,
        approveRemarks: this.ruleForm.approveRemarks
      };
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return;
        if (this.ruleForm.approveStatus == '2') {
          let res = await getPassAudit(params);
          if (res.errno === 0) {
            this.ruleForm = {
              approveStatus: '2',
              approveRemarks: ''
            };
            this.$bus.$emit('updateDetails');
          }
        }
        if (this.ruleForm.approveStatus == '3') {
          let res = await getRejectAudit(params);
          if (res.errno === 0) {
            this.ruleForm = {
              approveStatus: '2',
              approveRemarks: ''
            };
            this.$bus.$emit('updateDetails');
          }
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.review {
  & ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
  padding: 50px 60px 40px 60px;
  background-color: #fff;
  &-content {
    text-align: center;
    .text {
      font-size: 20px;
      color: #6ab3ff;
      line-height: 32px;
    }
  }
  &-textarea {
    margin: 30px 0 0 0;
    & ::v-deep .el-textarea__inner {
      height: 130px;
    }
  }
}
</style>
